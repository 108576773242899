const Swal = require('sweetalert2');

const showDeleteAlert = (title, desc, deleteItem, deleteFunc) => {
  Swal.fire({
    title,
    text: desc,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: 'rgb(221, 51, 51)',
    cancelButtonColor: '#E5EAEE',
    confirmButtonText: 'Xóa',
    cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
    reverseButtons: true,
    showClass: {
      popup: 'animate__animated animate__fadeInDown',
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp',
    },
    position: 'top',
  }).then((result) => {
    if (result && result.isConfirmed) {
      deleteFunc(deleteItem);
    }
  });
};

const showConfirmNotify = ({
  title,
  text = '',
  icon = 'warning',
  actionName,
  confirmButtonColor = 'rgb(221, 51, 51)',
  html = '',
  ...props
}) => {
  return Swal.fire({
    title,
    text,
    html,
    icon,
    confirmButtonColor,
    showCancelButton: true,
    cancelButtonColor: '#E5EAEE',
    confirmButtonText: actionName,
    reverseButtons: true,
    cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
    ...props,
  });
};

const showConfirmDialog = (title, desc) => {
  return new Promise((resolve, reject) => {
    Swal.fire({
      title: title,
      text: desc,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(221, 51, 51)',
      cancelButtonColor: '#E5EAEE',
      confirmButtonText: 'Xác nhận',
      cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
      reverseButtons: true,
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        resolve();
      } else {
        reject();
      }
    });
  });
};

const showLoadingDialog = (title, desc) => {
  Swal.fire({
    title: title,
    text: desc,
    allowOutsideClick: false,
    allowEscapeKey: false,
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  });
};

const showSuccessNotification = (title, desc) => {
  Swal.fire({
    icon: 'success',
    title: title,
    text: desc,
    timer: 2000,
    timerProgressBar: true,
    showConfirmButton: false,
  });
};

const closeSwal = () => {
  Swal.close();
};

module.exports = {
  showDeleteAlert,
  showConfirmNotify,
  showConfirmDialog,
  showLoadingDialog,
  showSuccessNotification,
  closeSwal,
};
